import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

function SEO({
  title,
  description,
  keywords,
  serviceAddress,
  siteConfig,
  brand
}) {
  return (
    <Helmet>
      <title>
        {title +
          (title && title.indexOf(siteConfig.brand) === -1
            ? ' | ' + siteConfig.brand
            : '')}
      </title>
      <meta
        name="description"
        content={
          description
            ? description
            : 'Find a lower rate and SAVE on your next electricity bill.'
        }
      />
      <meta
        name="keywords"
        content={
          (keywords ? keywords + ',' : '') +
          'smarter choice, texas electric rates, texas power, power to choose'
        }
      />
      {brand && siteConfig.id !== brand ? (
        <meta name="robots" content="noindex" />
      ) : null}
    </Helmet>
  );
}

function mapStateToProps(state) {
  return {
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SEO);
