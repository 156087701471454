import React from 'react';
import SEO from '../components/SEO';
import ContactPage from '../components/ContactPage';
export default function ({}) {
  return (
    <>
      <SEO
        title={'Contact Us'}
        description={
          'Got a question? Send us a message and we will get back to you.'
        }
      />

      <ContactPage />
    </>
  );
}
